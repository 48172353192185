.App {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-card {
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
}

.timer-card .ant-statistic {
  margin-bottom: 1rem;
}

.timer-card .ant-statistic-title {
  font-size: 1rem;
  color: #555;
}

.timer-card .ant-statistic-content {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}
